import React from 'react';
import {HashRouter,Routes,Route} from "react-router-dom";

// import './App.css';
import TopNav from "./components/templates/TopNav";
// import Home from "./components/pages/home/Home";
import Login from "./components/pages/auth/Login";
import Maintenance from "./components/pages/maintenance/Maintenance"
import MaintenanceMenu from "./components/pages/maintenance/MaintenanceMenu"
import MaintainClients from "./components/pages/maintenance/MaintainClients"
import MaintainProviders from "./components/pages/maintenance/MaintainProviders"
import MaintainPortfolios from "./components/pages/maintenance/MaintainPortfolios";
import MaintainBuildings from "./components/pages/maintenance/MaintainBuildings";
import MaintainUnits from "./components/pages/maintenance/MaintainUnits";
import MaintainUtilityAccounts from "./components/pages/maintenance/MaintainUtilityAccounts";
import MaintainBills from "./components/pages/maintenance/MaintainBills";
import MaintainTenants from "./components/pages/maintenance/MaintainTenants";
// import MaintainFees from "./components/pages/maintenance/MaintainFees";
import MaintainPWAccounts from "./components/pages/maintenance/MaintainPWAccounts";
import MaintainRawImports from "./components/pages/maintenance/MaintainRawImports";
import CalculateUtilities from "./components/pages/calculate/CalculateUtilities";
import AdministerSettings from "./components/pages/admin/AdministerSettings";
import AdministerUsers from "./components/pages/admin/AdministerUsers";

import {ProtectedRoute} from "./components/pages/auth/ProtectedRoute";
import {AuthProvider} from "./utils/useAuth";
import MaintainSmartMeters from "./components/pages/maintenance/MaintainSmartMeters";

const protect = (module) => {
  return <ProtectedRoute>{module}</ProtectedRoute>;
};

function App() {
  return (
    <React.StrictMode>
      <HashRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<TopNav/>}>
              <Route index element={<Login/>} />
              <Route path="login" element={<Login/>} />
              <Route path="profile" element={protect(<AdministerSettings/>)} />
              <Route path="administerusers" element={protect(<AdministerUsers/>)} />
              <Route path="calculateutilities" element={protect(<CalculateUtilities/>)} />
              <Route path="imports" element={protect(<MaintainRawImports/>)} />
              <Route path="maintenance" element={protect(<MaintenanceMenu/>)}>
                <Route index element={<Maintenance/>} />
                <Route path="clients" element={<MaintainClients/>} />
                <Route path="providers" element={<MaintainProviders/>} />
                <Route path="portfolios" element={<MaintainPortfolios/>} />
                <Route path="buildings" element={<MaintainBuildings/>} />
                <Route path="units" element={<MaintainUnits/>} />
                <Route path="utilityaccounts" element={<MaintainUtilityAccounts/>} />
                <Route path="bills" element={<MaintainBills/>} />
                <Route path="smartmeters" element={<MaintainSmartMeters/>} />
                {/* SAMI-108 <Route path="fees" element={<MaintainFees/>} />*/}
                <Route path="tenants" element={<MaintainTenants/>} />
                <Route path="pwaccounts" element={<MaintainPWAccounts/>} />
              </Route>
              {/*<Route path="admin" element={protect(<AdminMenu/>)}>*/}
              {/*  <Route index element={<AdministerSettings/>} />*/}
              {/*  <Route path="users" element={<AdministerUsers/>} />*/}
              {/*</Route>*/}
            </Route>
          </Routes>
        </AuthProvider>
      </HashRouter>
    </React.StrictMode>
  );
}

export default App;